var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"white red--text-juranews pt-3 text-h5",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mb-3",attrs:{"cols":"12"}},[_vm._v("Fascicoli")])],1),_c('v-row',{staticClass:"white red--text-juranews pt-3",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-card',{attrs:{"max-height":"300"}},[(_vm.dossier.children.length > 0)?_c('v-treeview',{staticClass:"dossier-tree",attrs:{"items":_vm.dossier.children,"return-object":"","dense":"","open-all":"","activatable":""},on:{"update:active":_vm.selectActiveDocument},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(item.isDossier)?_c('v-icon',[_vm._v(" "+_vm._s(open ? 'mdi-folder-open' : 'mdi-folder')+" ")]):_c('v-icon',[_vm._v(" mdi-file-document-outline ")])]}}],null,false,1519002107)}):_vm._e()],1),(_vm.dossier.children.length == 0)?_c('div',[_vm._v(" Non ci sono ancora fascicoli ")]):_vm._e()],1),(_vm.dossier.children)?_c('v-col',{staticClass:"pl-5",attrs:{"cols":"4"}},[(_vm.dossier.children.length > 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","elevation":"2","fab":"","icon":"","outlined":"","raised":"","rounded":"","small":"","text":"","tile":"","disabled":!_vm.renameEnabled},on:{"click":_vm.rename}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-rename-box ")])],1)]}}],null,false,1206929970)},[_c('span',[_vm._v("Rinomina")])])],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","elevation":"2","fab":"","icon":"","outlined":"","raised":"","rounded":"","small":"","text":"","tile":"","disabled":!_vm.moveEnabled},on:{"click":_vm.move}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-arrow-right-box ")])],1)]}}],null,false,4062054774)},[_c('span',[_vm._v("Sposta")])])],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","elevation":"2","fab":"","icon":"","outlined":"","raised":"","rounded":"","small":"","text":"","tile":"","disabled":!_vm.deleteEnabled},on:{"click":_vm.deleteDossierDocument}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,false,2239537721)},[_c('span',[_vm._v("Elimina")])])],1)],1):_vm._e(),_c('v-row',{staticClass:"mt-0 mb-5 pb-0"},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","elevation":"2","fab":"","icon":"","outlined":"","raised":"","rounded":"","small":"","text":"","tile":""},on:{"click":_vm.createDossier}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-folder-plus ")])],1)]}}],null,false,3109913480)},[_c('span',[_vm._v("Aggiungi fascicolo")])])],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","elevation":"2","fab":"","icon":"","outlined":"","raised":"","rounded":"","small":"","text":"","tile":""},on:{"click":_vm.createDocument}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-file-plus ")])],1)]}}],null,false,1430109378)},[_c('span',[_vm._v("Aggiungi documento")])])],1)],1)],1):_vm._e()],1),(_vm.dossier.children.length > 0 && _vm.showEditor)?_c('jn-editor',{staticClass:"pt-10"}):_vm._e(),_c('v-snackbar',{attrs:{"timeout":_vm.alertTimeout,"centered":"","height":"80"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"red white--text","text":""},on:{"click":function($event){_vm.alert = false}}},'v-btn',attrs,false),[_vm._v(" Chiudi ")])]}}]),model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}},[_vm._v(" "+_vm._s(_vm.alertMessage)+" ")]),_c('v-dialog',{attrs:{"persistent":"","max-width":"700"},model:{value:(_vm.showRename),callback:function ($$v) {_vm.showRename=$$v},expression:"showRename"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Nuovo nome ")]),_c('v-card-text',[_c('v-text-field',{model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"red-juranews elevation-0 rounded-0 mb-7",attrs:{"dark":"","text":""},on:{"click":function($event){_vm.showRename = false}}},[_vm._v(" ANNULLA ")]),_c('v-btn',{staticClass:"red-juranews elevation-0 rounded-0 mb-7",attrs:{"dark":"","text":""},on:{"click":function($event){return _vm.saveRename()}}},[_vm._v(" SALVA ")])],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"700"},model:{value:(_vm.showMove),callback:function ($$v) {_vm.showMove=$$v},expression:"showMove"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Fascicolo di destinazione ")]),_c('v-card-text',[_c('v-treeview',{staticStyle:{"cursor":"pointer"},attrs:{"items":_vm.dossier.children,"return-object":"","dense":"","open-all":"","activatable":""},on:{"update:active":_vm.selectTargetDossier},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(item.isDossier)?_c('v-icon',[_vm._v(" "+_vm._s(open ? 'mdi-folder-open' : 'mdi-folder')+" ")]):_c('v-icon',[_vm._v(" mdi-file-document-outline ")])]}}])})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"red-juranews elevation-0 rounded-0 mb-7",attrs:{"dark":"","text":""},on:{"click":function($event){_vm.showMove = false}}},[_vm._v(" ANNULLA ")]),_c('v-btn',{staticClass:"red-juranews elevation-0 rounded-0 mb-7",attrs:{"dark":"","text":""},on:{"click":function($event){return _vm.saveMove()}}},[_vm._v(" SALVA ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }