





























































































































/* eslint-disable @typescript-eslint/camelcase */

import { Component, Vue, Watch } from "vue-property-decorator";
import { JnService } from "@/services/jn.service";
import { Users } from "@/models/dashboard/users.model";
import moment from "moment";
import { ValidationObserver } from "vee-validate";

@Component({
  components: {
    ValidationObserver,
  },
})
export default class JnDashboardUsers extends Vue {
  headers = [
    {
      text: "Utente",
      align: "start",
      sortable: true,
      value: "username",
    },
    { text: "Ultimo accesso", value: "last_access", sortable: true },
    {
      text: "Cambio password richiesto",
      value: "change_required",
      sortable: false,
    },
    { text: "Attivo", value: "active", sortable: false },
  ];

  loading = true;
  users: Users[] = [];

  options = {
    sortDesc: true,
    sortBy: ["last_access"],
    page: 1,
    itemsPerPage: 20,
  };
  sortBy = "last_access";
  sortDesc = true;
  page = 1;
  itemsPerPage = 20;
  usersLength = 0;

  optDataDa = "";
  optDataDaFormatted = "";
  dataDa = false;
  optDataAFormatted = "";
  dataA = false;
  optDataA = "";
  optUsername = "";

  isFormValid = false;
  validationMessage = "";

  async beforeMount() {
    await this.loadFromDatabase();
    await JnService.countDocAccessStats();
    this.checkFormValidation();
  }

  @Watch("sortBy")
  @Watch("sortDesc")
  @Watch("itemsPerPage")
  @Watch("page")
  async updateSort() {
    await this.loadFromDatabase();
  }

  async loadFromDatabase() {
    this.loading = true;
    const allUsers = await JnService.getUsers(
      (this.page - 1) * this.itemsPerPage,
      this.itemsPerPage,
      this.sortBy,
      this.sortDesc ? "DESC" : "ASC",
      this.optDataDaFormatted == null ? "" : this.optDataDaFormatted,
      this.optDataAFormatted == null ? "" : this.optDataAFormatted,
      this.optUsername == null ? "" : this.optUsername
    );
    this.usersLength = Number((await JnService.countUsers()).data.count);
    this.users = [];
    for (const d of allUsers.data) {
      if (d.last_access && d.last_access != null) {
        this.users.push(
          new Users({
            id: d.id,
            username: d.username,
            active: d.active,
            changeRequired: d.change_required,
            lastAccess: d.last_access,
          })
        );
      }
    }
    this.loading = false;
  }

  parseDate(date: string) {
    return date ? moment(date, "DD-MM-YYYY").format("YYYY-MM-DD") : "";
  }

  clearUsername() {
    this.optUsername = "";
    this.checkFormValidation();
  }
  clearDataDa() {
    this.optDataDa = "";
    this.checkFormValidation();
  }
  clearDataA() {
    this.optDataA = "";
    this.checkFormValidation();
  }
  saveDataDa(date: any) {
    this.optDataDaFormatted = moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
    this.dataDa = false;
    this.checkFormValidation();
  }

  saveDataA(date: any) {
    this.optDataAFormatted = moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
    this.dataA = false;
    this.checkFormValidation();
  }

  checkFormValidation() {
    if (this.optDataDa && this.optDataA) {
      if (moment(this.optDataDa).isBefore(moment(this.optDataA))) {
        this.isFormValid = true;
      } else {
        this.isFormValid = false;
        this.validationMessage =
          "La data di inizio deve essere precedente alla data di fine";
      }
    } else {
      this.isFormValid = true;
      this.validationMessage = "";
    }
  }

  formatDate(value: any) {
    if (value) {
      return moment(value).format("DD-MM-YYYY HH:mm:ss");
    } else {
      return "";
    }
  }

  async saveActive(user: any) {
    await JnService.updateUser(user);
  }
}
