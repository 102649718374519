















































































































































import { Component, Vue } from "vue-property-decorator";
import { JnService } from "@/services/jn.service";
import { User } from "@/models/crm/user.model";
import { CrmInfo } from "@/models/crm/crmInfo.model";
import { Utils } from "@/services/utils";
import JnNotifications from "@/views/profile/Notifications.vue";
import JnNotificationsPreferences from "@/views/profile/NotificationsPreferences.vue";
import JnDossier from "@/views/profile/Dossier.vue";
import JnSubscription from "@/views/profile/Subscription.vue";
import JnProfile from "@/views/profile/Profile.vue";
import JnDashboardDocAccessStats from "@/views/profile/dashboard/DocAccessStats.vue";
import JnDashboardDocSearchStats from "@/views/profile/dashboard/DocSearchStats.vue";
import JnDashboardUsers from "@/views/profile/dashboard/Users.vue";
import JnProgress from "@/views/common/Progress.vue";
import EventBus from "@/services/event.bus";

@Component({
  components: {
    JnNotifications,
    JnNotificationsPreferences,
    JnDossier,
    JnSubscription,
    JnProfile,
    JnDashboardDocAccessStats,
    JnDashboardDocSearchStats,
    JnDashboardUsers,
    JnProgress,
  },
})
export default class JnAreaPersonale extends Vue {
  crmInfo = new CrmInfo(
    new User(
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      false
    ),
    undefined,
    undefined
  );

  loading = true;

  showProfile = true;
  showNotifications = false;
  showNotificationsPreferences = false;
  showDossier = false;
  showSubscription = false;
  showDashboardDocAccessStats = false;
  showDashboardDocSearchStats = false;
  showDashboardUsers = false;

  hasDashboardProfile = false;

  async beforeMount() {
    EventBus.$on("updateProfile", () => {
      this.updateProfile();
    });
    this.updateProfile();
  }

  async updateProfile() {
    this.loading = true;
    const usernameFromCookie = this.$cookies.get("username");

    if (usernameFromCookie) {
      const profiles = await JnService.getProfiles(String(usernameFromCookie));
      this.hasDashboardProfile = Utils.checkProfile(profiles, "dashboard");
      const user = await JnService.getCrmUser(
        String(usernameFromCookie),
        undefined
      );
      const purchase = await JnService.getActivePurchaseOrder(user.id);
      if (purchase) {
        const service = await JnService.getService(purchase.serviceId);
        this.crmInfo = new CrmInfo(user, purchase, service);
      } else {
        this.crmInfo = new CrmInfo(user, undefined, undefined);
      }
    }
    this.loading = false;
  }

  goToProfile() {
    this.showProfile = true;
    this.showNotifications = false;
    this.showNotificationsPreferences = false;
    this.showDossier = false;
    this.showSubscription = false;
    this.showDashboardDocAccessStats = false;
    this.showDashboardDocSearchStats = false;
    this.showDashboardUsers = false;
  }
  goToNotifications() {
    this.showProfile = false;
    this.showNotifications = true;
    this.showNotificationsPreferences = false;
    this.showDossier = false;
    this.showSubscription = false;
    this.showDashboardDocAccessStats = false;
    this.showDashboardDocSearchStats = false;
    this.showDashboardUsers = false;
  }
  goToNotificationsPreferences() {
    this.showProfile = false;
    this.showNotifications = false;
    this.showNotificationsPreferences = true;
    this.showDossier = false;
    this.showSubscription = false;
    this.showDashboardDocAccessStats = false;
    this.showDashboardDocSearchStats = false;
    this.showDashboardUsers = false;
  }
  goToDossier() {
    this.showProfile = false;
    this.showNotifications = false;
    this.showNotificationsPreferences = false;
    this.showDossier = true;
    this.showSubscription = false;
    this.showDashboardDocAccessStats = false;
    this.showDashboardDocSearchStats = false;
    this.showDashboardUsers = false;
  }
  goToSubscription() {
    this.showProfile = false;
    this.showNotifications = false;
    this.showNotificationsPreferences = false;
    this.showDossier = false;
    this.showSubscription = true;
    this.showDashboardDocAccessStats = false;
    this.showDashboardDocSearchStats = false;
    this.showDashboardUsers = false;
  }
  goToDashboardDocAccessStats() {
    this.showProfile = false;
    this.showNotifications = false;
    this.showNotificationsPreferences = false;
    this.showDossier = false;
    this.showSubscription = false;
    this.showDashboardDocAccessStats = true;
    this.showDashboardDocSearchStats = false;
    this.showDashboardUsers = false;
  }
  goToDashboardDocSearchStats() {
    this.showProfile = false;
    this.showNotifications = false;
    this.showNotificationsPreferences = false;
    this.showDossier = false;
    this.showSubscription = false;
    this.showDashboardDocAccessStats = false;
    this.showDashboardDocSearchStats = true;
    this.showDashboardUsers = false;
  }
  goToDashboardUsersManagement() {
    this.showProfile = false;
    this.showNotifications = false;
    this.showNotificationsPreferences = false;
    this.showDossier = false;
    this.showSubscription = false;
    this.showDashboardDocAccessStats = false;
    this.showDashboardDocSearchStats = false;
    this.showDashboardUsers = true;
  }
}
