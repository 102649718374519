
























































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { JnService } from '@/services/jn.service';
import { Dossier } from '@/models/dossier.model';
import EventBus from '@/services/event.bus';
import { DossierDocument } from '@/models/dossier.document.model';
import { Editor } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';

import JnEditor from '@/views/common/Editor.vue';
import { uuid } from 'vue-uuid';

@Component({
  components: { JnEditor },
})
export default class JnDossier extends Vue {
  dossier = new Dossier(String(this.$cookies.get('username')), []);

  alert = false;
  alertMessage = '';
  alertTimeout = 6000;

  editor = new Editor({
    extensions: [StarterKit],
    autofocus: true,
    editable: true,
    injectCSS: false,
    content: ' ',
  });

  selectedDossierDocument!: DossierDocument | undefined;
  showEditor = false;

  // Rename
  renameEnabled = false;
  showRename = false;
  name = '';
  rename() {
    if (this.selectedDossierDocument) {
      this.name = this.selectedDossierDocument.name!;
      this.showRename = true;
    } else {
      this.alertMessage = 'Selezionare un documento o un fascicolo.';
      this.alert = true;
    }
  }
  async saveRename() {
    this.selectedDossierDocument!.name = this.name;
    this.name = '';
    this.showRename = false;
    await JnService.saveDossier(this.dossier);
  }

  // Delete
  deleteEnabled = false;
  async deleteDossierDocument() {
    console.log(
      'deleteDossier:' + JSON.stringify(this.selectedDossierDocument)
    );
    if (
      this
        .selectedDossierDocument /* &&
      this.selectedDossierDocument.isDossier*/
    ) {
      this.dossier.removeDoc(
        this.selectedDossierDocument,
        this.dossier.children
      );
      if (!this.selectedDossierDocument.isDossier) {
        await JnService.deleteDossierDocument(this.selectedDossierDocument.id);
      } else {
        const allChildren: DossierDocument[] = [];
        this.selectedDossierDocument.getAllChildren(allChildren);
        for (const c of allChildren) {
          if (!c.isDossier) {
            await JnService.deleteDossierDocument(c.id);
          }
        }
      }
      await JnService.saveDossier(this.dossier);
      this.selectedDossierDocument = undefined;
    } else {
      this.alertMessage = 'Selezionare un fascicolo';
      this.alert = true;
    }
  }

  // Move
  moveEnabled = false;
  showMove = false;
  targetDossier!: DossierDocument | undefined;
  move() {
    if (this.selectedDossierDocument) {
      this.showMove = true;
    } else {
      this.alertMessage = 'Selezionare un documento o un fascicolo.';
      this.alert = true;
    }
  }
  async saveMove() {
    if (this.targetDossier && this.targetDossier.isDossier) {
      console.log(
        'saveMove(sourceDossierDocument): ' +
          JSON.stringify(this.selectedDossierDocument)
      );
      console.log(
        'saveMove(targetDossier): ' + JSON.stringify(this.targetDossier)
      );

      // Se si tratta di un documento, cambio id per eliminarlo
      const newTarget = this.selectedDossierDocument!.clone();
      if (!this.selectedDossierDocument?.isDossier) {
        this.selectedDossierDocument!.id = 'TO_BE_DELETED';
      } else {
        newTarget.id = uuid.v1();
      }
      this.targetDossier.addDoc(newTarget);
      console.log('saveMove(dossier): ' + JSON.stringify(this.dossier));
      this.dossier.removeDossier(
        this.selectedDossierDocument!,
        this.dossier.children
      );
      this.showMove = false;
      await JnService.saveDossier(this.dossier);
      this.selectedDossierDocument = undefined;
      EventBus.$emit('updateEditor', null);
    } else {
      this.alertMessage = 'Selezionare un fascicolo.';
      this.alert = true;
    }
  }

  // Create Dossier
  async createDossier() {
    console.log(
      'createDossier:' + JSON.stringify(this.selectedDossierDocument)
    );
    const newDossier = new DossierDocument(
      uuid.v1(),
      'Sottofascicolo',
      undefined,
      [],
      true
    );
    if (
      !this.selectedDossierDocument ||
      !this.selectedDossierDocument.isDossier
    ) {
      this.dossier.addDoc(newDossier);
    } else if (this.selectedDossierDocument.isDossier) {
      this.selectedDossierDocument.addDoc(newDossier);
    }
    await JnService.saveDossier(this.dossier);
    console.log('createDossier(dossier):' + JSON.stringify(this.dossier));
  }

  // Create document
  async createDocument() {
    console.log(
      'createDocument(selectedDossierDocument):' +
        JSON.stringify(this.selectedDossierDocument)
    );
    const newDocument = new DossierDocument(
      uuid.v1(),
      'Nuovo documento',
      'Nuovo testo',
      [],
      false
    );
    const res = await JnService.saveDossierDocument(newDocument);
    newDocument.id = res.id;
    if (
      (this.selectedDossierDocument &&
        !this.selectedDossierDocument.isDossier) ||
      !this.selectedDossierDocument
    ) {
      this.dossier.addDoc(newDocument);
    } else if (
      this.selectedDossierDocument &&
      this.selectedDossierDocument.isDossier
    ) {
      this.selectedDossierDocument.addDoc(newDocument);
    }
    await JnService.saveDossier(this.dossier);
  }

  async beforeMount() {
    const usernameFromCookie = this.$cookies.get('username') as string;
    EventBus.$on('dossier', async (dossier: Dossier) => {
      this.dossier = dossier;
    });
    EventBus.$on('editorSaved', async (html: string) => {
      this.selectedDossierDocument!.text = html;
      const res = await JnService.updateDossierDocument(
        this.selectedDossierDocument!
      );
      console.log('beforeMount: ' + JSON.stringify(res));
      if (res.name == 'Error') {
        this.alertMessage = 'Errore durante il salvataggio: ' + res.message;
      } else {
        this.alertMessage = 'Documento salvato con successo';
      }
      this.alert = true;
    });
    try {
      this.dossier = await JnService.getDossier(usernameFromCookie);
    } catch (err) {
      console.log('Dossier does not exist');
    }
  }

  async beforeUnmount() {
    this.editor.destroy();
  }

  async saveDossier() {
    await JnService.saveDossier(this.dossier);
  }

  async selectTargetDossier(selectedDocument: DossierDocument[]) {
    const targetDossierDocument = selectedDocument[0];
    this.targetDossier = targetDossierDocument;
  }

  async selectActiveDocument(selectedDocument: DossierDocument[]) {
    const selectedDossierDocument = selectedDocument[0];

    this.selectedDossierDocument = selectedDossierDocument;
    if (selectedDossierDocument) {
      this.renameEnabled = true;
      this.deleteEnabled = true;
      this.moveEnabled = true;
      if (!selectedDossierDocument.isDossier) {
        const document = await JnService.getDossierDocument(
          Number(selectedDossierDocument.id)
        );
        //this.selectedDossierDocument = selectedDossierDocument;
        EventBus.$emit('updateEditor', document.text);
        this.$store.commit('dossierDocument', document.text);
        this.showEditor = true;
      } else {
        EventBus.$emit('updateEditor', null);
        this.$store.commit('dossierDocument', undefined);
        this.showEditor = false;
      }
    } else {
      this.renameEnabled = false;
      this.deleteEnabled = false;
      this.moveEnabled = false;
      this.selectedDossierDocument = undefined;
      this.showEditor = false;
      EventBus.$emit('updateEditor', null);
    }
  }
}
