var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[(_vm.editor.getText() != '')?_c('v-btn',{staticClass:"red-juranews elevation-0 rounded-0 mb-7 ml-3",attrs:{"dark":""},on:{"click":_vm.save}},[_vm._v(" SALVA DOCUMENTO ")]):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{ 'is-active': _vm.editor.isActive('bold') },attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor.chain().focus().toggleBold().run()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-bold")])],1)]}}])},[_c('span',[_vm._v("Grassetto")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{ 'is-active': _vm.editor.isActive('italic') },attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor.chain().focus().toggleItalic().run()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-italic")])],1)]}}])},[_c('span',[_vm._v("Corsivo")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{ 'is-active': _vm.editor.isActive('strike') },attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor.chain().focus().toggleStrike().run()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-strikethrough")])],1)]}}])},[_c('span',[_vm._v("Barrato")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor.chain().focus().unsetAllMarks().run()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-clear")])],1)]}}])},[_c('span',[_vm._v("Elimina formattazione")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{ 'is-active': _vm.editor.isActive('paragraph') },attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor.chain().focus().setParagraph().run()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-paragraph")])],1)]}}])},[_c('span',[_vm._v("Paragrafo")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{ 'is-active': _vm.editor.isActive('heading', { level: 1 }) },attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor.chain().focus().toggleHeading({ level: 1 }).run()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-header-1")])],1)]}}])},[_c('span',[_vm._v("Titolo 1")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{ 'is-active': _vm.editor.isActive('heading', { level: 2 }) },attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor.chain().focus().toggleHeading({ level: 2 }).run()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-header-2")])],1)]}}])},[_c('span',[_vm._v("Titolo 2")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{ 'is-active': _vm.editor.isActive('heading', { level: 3 }) },attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor.chain().focus().toggleHeading({ level: 3 }).run()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-header-3")])],1)]}}])},[_c('span',[_vm._v("Titolo 3")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{ 'is-active': _vm.editor.isActive('heading', { level: 4 }) },attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor.chain().focus().toggleHeading({ level: 4 }).run()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-header-4")])],1)]}}])},[_c('span',[_vm._v("Titolo 4")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{ 'is-active': _vm.editor.isActive('heading', { level: 5 }) },attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor.chain().focus().toggleHeading({ level: 5 }).run()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-header-5")])],1)]}}])},[_c('span',[_vm._v("Titolo 5")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{ 'is-active': _vm.editor.isActive('heading', { level: 6 }) },attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor.chain().focus().toggleHeading({ level: 6 }).run()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-header-6")])],1)]}}])},[_c('span',[_vm._v("Titolo 6")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{ 'is-active': _vm.editor.isActive('bulletList') },attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor.chain().focus().toggleBulletList().run()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-list-bulleted")])],1)]}}])},[_c('span',[_vm._v("Elenco puntato")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{ 'is-active': _vm.editor.isActive('orderedList') },attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor.chain().focus().toggleOrderedList().run()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-list-numbered")])],1)]}}])},[_c('span',[_vm._v("Elenco numerato")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor.chain().focus().clearNodes().run()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-reorder-horizontal")])],1)]}}])},[_c('span',[_vm._v("Eliminca elenco")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{ 'is-active': _vm.editor.isActive('code') },attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor.chain().focus().toggleCode().run()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-code-tags")])],1)]}}])},[_c('span',[_vm._v("Codice")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{ 'is-active': _vm.editor.isActive('codeBlock') },attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor.chain().focus().toggleCodeBlock().run()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-code-json")])],1)]}}])},[_c('span',[_vm._v("Blocco di codice")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{ 'is-active': _vm.editor.isActive('blockquote') },attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor.chain().focus().toggleBlockquote().run()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-comment-quote")])],1)]}}])},[_c('span',[_vm._v("Citazione")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor.chain().focus().setHorizontalRule().run()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-minus")])],1)]}}])},[_c('span',[_vm._v("Linea orizzontale")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor.chain().focus().setHardBreak().run()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-subdirectory-arrow-left")])],1)]}}])},[_c('span',[_vm._v("A capo orizzontale")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor.chain().focus().undo().run()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-undo")])],1)]}}])},[_c('span',[_vm._v("Annulla")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){_vm.editor.chain().focus().redo().run()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-redo")])],1)]}}])},[_c('span',[_vm._v("Ripeti")])])],1),_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"5"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.extendEditor()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Estendi editor")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.reduceEditor()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-minus")])],1)]}}])},[_c('span',[_vm._v("Riduci editor")])])],1)],1),_c('v-divider'),_c('editor-content',{style:(_vm.cssProps),attrs:{"editor":_vm.editor}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }