var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"white red--text-juranews pt-3 text-h5",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mb-3",attrs:{"cols":"12"}},[_vm._v("Gestione notifiche")])],1),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{attrs:{"id":"formNotification"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"11"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"red--text-juranews"},[_c('strong',[_vm._v("* ")])]),_vm._v("Titolo ")]},proxy:true}],null,true),model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"11"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"solo":"","error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"red--text-juranews"},[_c('strong',[_vm._v("* ")])]),_vm._v("Testo ")]},proxy:true}],null,true),model:{value:(_vm.body),callback:function ($$v) {_vm.body=$$v},expression:"body"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"11"}},[_c('v-text-field',{attrs:{"label":"Link"},model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.platforms,"item-text":"label","item-value":"value","error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"red--text-juranews"},[_c('strong',[_vm._v("* ")])]),_vm._v("Piattaforma ")]},proxy:true}],null,true),model:{value:(_vm.platform),callback:function ($$v) {_vm.platform=$$v},expression:"platform"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.areeTematiche,"item-text":"label","item-value":"value","error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"red--text-juranews"},[_c('strong',[_vm._v("* ")])]),_vm._v("Area Tematica ")]},proxy:true}],null,true),model:{value:(_vm.areaTematica),callback:function ($$v) {_vm.areaTematica=$$v},expression:"areaTematica"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"11"}},[_c('v-btn',{staticClass:"red-juranews relevation-0 rounded-0 text-center",attrs:{"disabled":invalid},on:{"click":_vm.sendNotification}},[_vm._v(" INVIA ")])],1)],1)],1)]}}])}),_c('v-snackbar',{attrs:{"timeout":_vm.alertTimeout,"top":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"red white--text","text":""},on:{"click":function($event){_vm.alert = false}}},'v-btn',attrs,false),[_vm._v(" Chiudi ")])]}}]),model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}},[_vm._v(" "+_vm._s(_vm.alertMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }