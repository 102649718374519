













































































































import { Component, Vue } from 'vue-property-decorator';
import { JnService } from '@/services/jn.service';
import { User } from '@/models/crm/user.model';

@Component({
  components: {},
})
export default class JnProfile extends Vue {
  readonly = true;

  user = new User(
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    false
  );

  async beforeMount() {
    const usernameFromCookie = this.$cookies.get('username');

    if (usernameFromCookie) {
      this.user = await JnService.getCrmUser(
        String(usernameFromCookie),
        undefined
      );
    }
  }
}
