var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"white red--text-juranews pt-3 text-h5",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mb-3",attrs:{"cols":"12"}},[_vm._v("Gestione utenti")])],1),_c('v-form',{attrs:{"id":"usersFilterForm"}},[_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"clearable":"","label":"Nome utente"},on:{"click:clear":_vm.clearUsername},model:{value:(_vm.optUsername),callback:function ($$v) {_vm.optUsername=$$v},expression:"optUsername"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3","lg":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","label":"Da","readonly":"","prepend-icon":"mdi-calendar","persistent-hint":"","hint":"Formato gg-mm-aaaa"},on:{"blur":function($event){_vm.optDataDa = _vm.parseDate(_vm.optDataDaFormatted)},"click:clear":_vm.clearDataDa},model:{value:(_vm.optDataDaFormatted),callback:function ($$v) {_vm.optDataDaFormatted=$$v},expression:"optDataDaFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dataDa),callback:function ($$v) {_vm.dataDa=$$v},expression:"dataDa"}},[_c('v-date-picker',{attrs:{"min":"1950-01-01"},on:{"change":_vm.saveDataDa},model:{value:(_vm.optDataDa),callback:function ($$v) {_vm.optDataDa=$$v},expression:"optDataDa"}})],1)],1),_c('v-col',{attrs:{"cols":"3","lg":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","label":"A","readonly":"","prepend-icon":"mdi-calendar","persistent-hint":"","hint":"Formato gg-mm-aaaa"},on:{"click:clear":_vm.clearDataA,"blur":function($event){_vm.optDataA = _vm.parseDate(_vm.optDataAFormatted)}},model:{value:(_vm.optDataAFormatted),callback:function ($$v) {_vm.optDataAFormatted=$$v},expression:"optDataAFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dataA),callback:function ($$v) {_vm.dataA=$$v},expression:"dataA"}},[_c('v-date-picker',{attrs:{"min":"1950-01-01"},on:{"change":_vm.saveDataA},model:{value:(_vm.optDataA),callback:function ($$v) {_vm.optDataA=$$v},expression:"optDataA"}})],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"red-juranews text-h7 elevation-0 rounded-0 mr-1 mt-3",attrs:{"disabled":!_vm.isFormValid,"width":"140"},on:{"click":_vm.loadFromDatabase}},[_vm._v(" AGGIORNA ")])],1),_c('v-col',[_c('div',{staticClass:"red--text-juranews mt-7"},[_vm._v(_vm._s(_vm.validationMessage))])])],1)],1),_c('v-data-table',{staticClass:"elevation-1 mt-8",attrs:{"headers":_vm.headers,"items":_vm.users,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.usersLength,"loading":_vm.loading,"footer-props":{
      'items-per-page-options': [20, 50, 100],
    },"must-sort":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"item.last_access",fn:function(ref){
    var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDate(value))+" ")]}},{key:"item.change_required",fn:function(ref){
    var value = ref.value;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(value.change_required),callback:function ($$v) {_vm.$set(value, "change_required", $$v)},expression:"value.change_required"}})]}},{key:"item.active",fn:function(ref){
    var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"ripple":false},on:{"click":function($event){return _vm.saveActive(item)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }